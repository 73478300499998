import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CategoryIcon from '@material-ui/icons/Category';
import PeopleIcon from '@material-ui/icons/People';
import { Link } from 'react-router-dom';

const linkStyle = {
  color: 'black',
  textDecoration: 'none'
};

export const mainListItems = (
  <div>
    <Link to="/questions" style={linkStyle}>
      <ListItem button onClick={() => { console.log('Questions') }}>
        <ListItemIcon>
          <QuestionAnswerIcon />
        </ListItemIcon>
        <ListItemText primary="Questions" />
      </ListItem>
    </Link>
    <Link to="/playlists" style={linkStyle}>
      <ListItem button onClick={() => { console.log('Playlists') }}>
        <ListItemIcon>
          <SubscriptionsIcon />
        </ListItemIcon>
        <ListItemText primary="Playlists" />
      </ListItem>
    </Link>
    <Link to="/categories" style={linkStyle}>
      <ListItem button onClick={() => { console.log('Categories') }}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </ListItem>
    </Link>
    <Link to="/users" style={linkStyle}>
      <ListItem button onClick={() => { console.log('Users') }}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    </Link>
  </div>
);