import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import eu from '../assets/eu.jpg';
import axios from 'axios';

function Copyright() {

  return (

    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://euinfo.rs/">
        EU Info Centar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>

  );

}

const useStyles = makeStyles((theme) => ({

  root: {
    height: '100vh',
  },
  image: {
    //backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: 'url('+ eu +')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));

export default function SignInSide(props) {

  const classes = useStyles();

  const [email, setUserName] = useState();

  const [password, setPassword] = useState();

  const [showErrorMessage, setShowErrorMessage] = useState();

  const handleShowErrorMessage = (asd) => {
        setShowErrorMessage(asd);
  };
  

  const handleLogin = e => {
    e.preventDefault();
    axios.post('https://kvizapi.europa.rs/api/auth/login', {
        email: email,
        password: password
    }).then(response => {
        if(response.data.status === "Success") {
          props.getToken(response.data.data.token);
          props.login();
        }        
    }).catch(err => {
      console.log("Login failed")
      if (err.response.status === 401) { 
        handleShowErrorMessage(true);
        setTimeout(() => {
          handleShowErrorMessage(false);
        }, 6000);
      }
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
			<form className={classes.form} noValidate onSubmit={handleLogin}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
					onChange={e => setUserName(e.target.value)}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					onChange={e => setPassword(e.target.value)}
				/>
            {showErrorMessage ? <div>Credentials don't match</div> : null}
            

				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Sign In
				</Button>

				<Box mt={5}>
					<Copyright />
				</Box>
			</form>
        </div>
      </Grid>
    </Grid>
  );

}