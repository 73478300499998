import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';

// xgrid
import { DataGridPro } from '@mui/x-data-grid-pro';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import WQQuestionEdit from '../forms/WQQuestionEdit';
import WQQuestionNew from '../forms/WQQuestionNew';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },
    loader: {
        textAlign: 'right',
        paddingRight: '25px'
    }
}));

export default function WQQuestions(props) {
    
    //Edit Modal
    const [openEdit, setOpenEdit] = useState(false);
    
    const handleOpenEdit = (questionId) => {
        setOpenEdit(true);
        setQuestionEdit(questions.find(x => x.id === questionId))
    };
    
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const [questionEdit, setQuestionEdit] = useState();

    //New Modal
    const [openNew, setOpenNew] = useState(false);    
    const handleOpenNew = () => {
        setSuccess(false);
        setOpenNew(true);        
    };
    const handleCloseNew = () => {
        setOpenNew(false);
    };

    //Messages
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleCloseSnackbar = () => {
        setSuccess(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'question',
            headerName: 'Question',
            width: 480
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 250
        },
        {
            field: 'hardness',
            headerName: 'Level',
            type: 'number',
            width: 110
        },
        {
            field: 'edit_id',
            headerName: 'Tools',
            width: 250,
            disableColumnMenu: true,
            disableColumnReorder: true,
            renderCell: (params) => (
                
                    <strong>                    
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<EditIcon />}
                            onClick={() => { handleOpenEdit(params.value) }}
                        >
                        Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={()=>{ handleDeleteQuestion(params.value) }}
                        >
                            Delete
                        </Button>
                    </strong>
                
            ),
        }
    ];    
    
    //Get List and Pagination
    const [page, setPage] = useState(1);
    const [questions, setQuestions] = useState();

    const [pageSize, setPageSize] = useState(5);

    const handleReload = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        getQuestions(params.get('page') ? params.get('page') : page);
    };
       
    useEffect(() => {
        handleReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // AJAX calls
    const instance = axios.create({
        baseURL: 'https://kvizapi.europa.rs/api/',
        timeout: 1000,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    
    const getQuestions = (asd) => {        
        if (sessionStorage.loggedIn) {
            props.showLoaderForQuestions(true);
            let questionsUrl = '/questions?per_page=1000';
            // if (asd > 1) {
            //     questionsUrl = '/questions?page=' + parseInt(asd);
            // }
            instance.get(questionsUrl, {
                
            }).then(response => {
                if(response.status === 200) {
                    let asd = response.data.data.map((item) => ({
                        id: item.id,
                        question: item.question,
                        hardness: item.hardness,
                        category: props.categories.filter(x => x.id === item.category_id)[0].name,
                        category_id: item.category_id,
                        edit_id: item.id,
                        answers: item.answers
                    }));
                    setQuestions(asd);
                    console.log(response.data);                  
                    setPage(response.data.current_page);
                }        
            }).catch(err => {
              console.log(err);
            }).finally(props.showLoaderForQuestions(false));
        }
    };

    const saveQuestionEdit = (id,qEdit) => {
        console.log(qEdit);
        if (sessionStorage.loggedIn) {
            props.showLoaderForQuestions(true);
            let questionsUrl = '/questions/'+ id;            
            instance.put(questionsUrl, qEdit).then(response => {
                //if(response.status === 200) {
                    setSuccessMessage('Saved!');
                    setSuccess(true);
                    handleReload();
                //}        
            }).catch(err => {
              console.log(err);
            }).finally(props.showLoaderForQuestions(false));
        }
    };

    const handleSaveNew = (questionForm) => {
        if (sessionStorage.loggedIn) {
            props.showLoaderForQuestions(true);
            let saveUrl = '/questions';
            instance.post(saveUrl, {
                ...questionForm
            }).then(response => {
                if (response.status === 201) {
                    setSuccessMessage("Saved!");
                    setSuccess(true);
                    handleCloseNew();
                    handleReload();
                }
            }).catch(err => {
              console.log(err);            
            }).finally(props.showLoaderForQuestions(false));
        }
    };

    const handleDeleteQuestion = (qId) => {
        if (sessionStorage.loggedIn) {
            props.showLoaderForQuestions(true);
            let deleteUrl = '/questions/' + qId;
            instance.delete(deleteUrl).then(response => {
                setSuccessMessage('Deleted!');
                setSuccess(true);
                handleReload();
            }).catch(err => {
              console.log(err.response.data.message);
            }).finally(props.showLoaderForQuestions(false));
        }
    };

    const classes = useStyles();

    return (
        <div>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{marginBottom: '17px'}}
            >
                <Grid item xs={2}>
                    <Fab color="primary" aria-label="add" onClick={() => { handleOpenNew() }}>
                        <AddIcon />
                    </Fab>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={success}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                        message={successMessage}
                    />
                </Grid>
                <Grid item xs={4} container>
                    {/* {questions !== undefined &&
                        SearchBox
                    } */}
                </Grid>
                <Grid item container xs={6} justify="flex-end">
                    {/* {questions !== undefined && 
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                    } */}
                </Grid>
            </Grid>
            {questions !== undefined ? <DataGridPro
                                    columns={columns}
                                    rows={questions}
                                    pagination
                                    autoHeight
                                    rowsPerPageOptions={[5,10,25]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                /> : null}
            {/* <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell align="right">Level</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions !== undefined ? questions.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    {row.question}
                                    
                                </TableCell>
                                <TableCell align="right">{row.hardness}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<EditIcon />}
                                        onClick={() => { handleOpenEdit(row.id) }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}
                                        onClick={()=>{ handleDeleteQuestion(row.id) }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer> */}

            {/* <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"                
                style={{marginTop: '17px', marginBottom: '17px'}}
            >
                <Grid item container xs={6} justify="flex-end">
                    {questions !== undefined && 
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                    }
                </Grid>
            </Grid> */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openEdit}
                onClose={handleCloseEdit}
                // disableBackdropClick={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openEdit}>
                    <div className={classes.paper}>                        
                        {questionEdit !== undefined && <WQQuestionEdit questionText={questionEdit} onHandleClose={handleCloseEdit} onSaveEdit={saveQuestionEdit} categories={props.categories} />}
                    </div>
                    
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openNew}
                onClose={handleCloseNew}
                // disableBackdropClick={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openNew}>
                    <div className={classes.paper}>                        
                        <WQQuestionNew categories={props.categories} onSaveNew={handleSaveNew} onHandleClose={handleCloseNew} loading={props.isLoading} success={success}/>
                    </div>
                    
                </Fade>
            </Modal>            
        </div>
        
    )
}
