import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  '84bf66419d14b2a3179f4e6f7e321540T1JERVI6Mjg4MjAsRVhQSVJZPTE2NjE5NDg3MDAwMDAsS0VZVkVSU0lPTj0x',
);

ReactDOM.render(
  <BrowserRouter>  
    <App />
  </BrowserRouter>,
  document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
