import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

// xgrid
import { DataGridPro } from '@mui/x-data-grid-pro';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },
    loader: {
        textAlign: 'right',
        paddingRight: '25px'
    }
}));

export default function WQUsers(props) {
    const classes = useStyles();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'name',
            headerName: 'Name',
            width: 250
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250
        },
        {
            field: 'edit_id',
            headerName: 'Tools',
            width: 250,
            disableColumnMenu: true,
            disableColumnReorder: true,
            renderCell: (params) => (
                
                    <strong>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={()=>{ handleDeleteUsers(params.value) }}
                        >
                            Delete
                        </Button>
                    </strong>
                
            ),
        }
    ]; 

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);

    const [pageSize, setPageSize] = useState(5);

    const handleReload = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        getUsers(params.get('page') ? params.get('page') : page);
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
    };
       
    useEffect(() => {
        handleReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const instance = axios.create({
        baseURL: 'https://kvizapi.europa.rs/api/',
        timeout: 5000,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    const getUsers = (asd) => {        
        if (sessionStorage.loggedIn) {
            props.showLoaderForUsers(true);
            let usersUrl = '/users?per_page=999999';
            instance.get(usersUrl, {
                
            }).then(response => {
                if(response.status === 200) {
                    let asd = response.data.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        edit_id: item.id
                    }));
                    setUsers(asd);
                    setPage(response.data.current_page);
                }        
            }).catch(err => {
              console.log(err.response.data.message);
            }).finally(props.showLoaderForUsers(false));
        }
    };

    const handleDeleteUsers = (id) => {
        if (sessionStorage.loggedIn) {
            props.showLoaderForUsers(true);
            let usersUrl = '/users/' + id;
            instance.delete(usersUrl).then(response => {
                if (response.data.status === 'Success') {                    
                    getUsers();
                    setSuccessMessage('Deleted!');
                    setSuccess(true);
                }
                console.log(response)
            }).catch(err => {
                console.log(err.response.data.message);
            }).finally(props.showLoaderForUsers(false));
        }
    }
    
    return (
        <div>
        <Grid container direction="row"
                justify="flex-start"
                alignItems="center"
                style={{marginBottom: '17px'}}>

            <Grid item xs={6}>
                {/* <TextField id="user-search" label="Search users" type="search" variant="outlined" /> */}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={success}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    message={successMessage}
                />
            </Grid>
            {/* <Grid item container xs={6} justify="flex-end">
                {users !== undefined && 
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                }
            </Grid> */}
            </Grid>
            {users !== undefined ? <DataGridPro
                                    columns={columns}
                                    rows={users}
                                    pagination
                                    autoHeight
                                    rowsPerPageOptions={[5,10,25]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                /> : null}
                
        {/* <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { users !== undefined ? users.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                            {row.id}
                        </TableCell>
                        <TableCell>
                            {row.name}
                        </TableCell>
                        <TableCell>
                            {row.email}
                        </TableCell>
                        <TableCell>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                startIcon={<EditIcon />}
                                onClick={() => { handleSelectedPlaylist(row.id) }}
                            >
                                Edit
                            </Button> */}
                                {/* {row.name !== 'Admin' &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() => { handleDeleteUsers(row.id) }}
                                    >
                                        Delete
                                    </Button>}
                        </TableCell>
                    </TableRow>
                    )): null }
                </TableBody>
            </Table>
            </TableContainer>  */}

            {/* <Grid container direction="row"
                justify="flex-start"
                alignItems="center"
                style={{marginTop: '17px'}}>

                <Grid item xs={6}>
                    
                </Grid>
                <Grid item container xs={6} justify="flex-end">
                    {users !== undefined && 
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                    }
                </Grid>
            </Grid> */}
                
         </div>   
        
    )
}
