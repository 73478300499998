import { React, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DataGridPro } from '@mui/x-data-grid-pro';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    datewrap: {
        marginBottom: 20
    },
    headerForm: {
        marginBottom: 50
    },
    formControl: {            
        minWidth: 240,
    },
    displayNumInput: {
        marginTop: 20,
        padding: 7
    }
}));

export default function WQPlaylistNew(props) {
    const classes = useStyles();

    const [questionList, setQuestionList] = useState([]);
    const [fromDateTime, setFromDateTime] = useState(new Date());
    const [toDateTime, setToDateTime] = useState(new Date());
    const [minToDateTime, setMinToDateTime] = useState(new Date());
    const [count, setCount] = useState(5);
    const [checked, setChecked] = useState([]);
    const [generate, setGenerate] = useState(false);
    const [playlistName, setPlaylistName] = useState('new');
    const [playlistPromocode, setPlaylistPromocode] = useState('');
    const [playlistTimer, setPlaylistTimer] = useState(16);
    const [categoryId, setCategoryId] = useState('');
    const [isEasyMode, setIsEasyMode] = useState(true);
    const [isOfflineMode, setIsOfflineMode] = useState(false);
    const [displayCount, setDisplayCount] = useState(0);
    const [quizInfo, setQuizInfo] = useState('');
    

    const handleCategoryId = (event) => {
        setCategoryId(event.target.value);
    };

    const handleIsEasyMode = (event) => {
        setIsEasyMode(event.target.checked);
    };

    const handleIsOfflineMode = (event) => {
        setIsOfflineMode(event.target.checked);
    };

    const handleDisplayCount = (event) => {
        setDisplayCount(event.target.value);
    };

    const handleQuizInfo = (event) => {
        setQuizInfo(event.target.value);
    };

    const [pageSize, setPageSize] = useState(5);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'question',
            headerName: 'Question',
            width: 480
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 250
        },
        {
            field: 'hardness',
            headerName: 'Level',
            type: 'number',
            width: 110
        }
    ];
    

    const handleFromDateChange = (date) => {
        setFromDateTime(date);
        setMinToDateTime(date);
        if (toDateTime < date) {
            setToDateTime(date);
        }
    };
    const handleToDateChange = (date) => {
        if (date > fromDateTime) {
            setToDateTime(date);
        }
    };

    const handleCount = (event) => {
        setCount(event.target.value);
    };

    const handlePlaylistName = (event) => {
        setPlaylistName(event.target.value);
    }

    const handlePlaylistPromocode = (event) => {
        setPlaylistPromocode(event.target.value);
    }

    const handlePlaylistTimer = (event) => {
        setPlaylistTimer(parseInt(event.target.value));
    }
    

    const instance = axios.create({
        baseURL: 'https://kvizapi.europa.rs/api/',
        timeout: 5000,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    const getQuestions = () => {
        if (sessionStorage.loggedIn) {
            instance.get('/questions?per_page=1000', {}).then(response => {
                if (response.status === 200) {
                   let asd = response.data.data.map((item) => ({
                        id: item.id,
                        question: item.question,
                        hardness: item.hardness,
                        category: props.categories.filter(x => x.id === item.category_id)[0].name                            
                    }));
                    //setQuestionList(response.data.data);
                    setQuestionList(asd);
                }
            }).catch(response => {
                console.log(response);
            });
        }
    };

    const formatDateTime = (dtm) => {
        let d = dtm.getUTCDate();
        let m = dtm.getUTCMonth() + 1;
        let min = dtm.getUTCMinutes();
        let h = dtm.getUTCHours();
        let year = dtm.getUTCFullYear();
        let month = (m < 10) ? '0'+m.toString() : m;
        let day = (d < 10) ? '0'+d.toString(): d;
        let hour = (h < 10) ? '0'+h.toString() : h;
        let minutes = (min < 10) ? '0'+min.toString() : min;

        return `${year}-${month}-${day} ${hour}:${minutes}`;
    }
    

    const handleSavePlaylist = () => {
        let from = formatDateTime(fromDateTime);
        let to = formatDateTime(toDateTime);
        let playlist = {};
        if (checked.length > 0) {
            playlist = {
                'name': playlistName,
                "date_from": from,
                "date_to": to,
                'questions': checked,
                'timer': parseInt(playlistTimer),
                'easy': isEasyMode ? 1 : 0,
                'question_count': displayCount > 0 ? displayCount : 0,
                'description': quizInfo,
                'offline': isOfflineMode ? 1 : 0
            };
            //props.save(playlist);
        } else if (generate) {
            playlist = {
                'name': playlistName,
                "date_from": from,
                "date_to": to,
                'count': count,
                'category': categoryId,
                'timer': parseInt(playlistTimer),
                'easy': isEasyMode ? 1 : 0,
                'question_count': displayCount > 0 ? displayCount : 0,
                'description': quizInfo,
                'offline': isOfflineMode ? 1 : 0
            };
        }
        if (playlistPromocode.length > 0) {
            playlist = {
                ...playlist,
                promo_code: playlistPromocode
            }
        }

        props.save(playlist);
        //console.log(playlist);

    }

    const handleClose = () => {
        props.close();
    }

    useEffect(() => {
        getQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2>Create new playlist</h2>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={3}>
                            <TextField id="playlist-name" label="Playlist name" onChange={handlePlaylistName} defaultValue={playlistName}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="playlist-promocode" label="Playlist Code" onChange={handlePlaylistPromocode} defaultValue={playlistPromocode}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="timer" label="Quiz Timer" onChange={handlePlaylistTimer} defaultValue={playlistTimer}/>  
                        </Grid>
                        <Grid item container xs={3} justify="flex-end">
                        <FormControlLabel
                            control={
                            <Switch
                                checked={isEasyMode}
                                onChange={handleIsEasyMode}
                                name="isEasyChecked"
                                color="primary"
                            />
                            }
                            label="Easy Mode"
                        />
                            <Button variant="outlined" onClick={() => {setGenerate(!generate)}}>Generate</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-between">
                            <KeyboardDatePicker
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-from"
                                label="From date:"
                                disablePast={true}
                                value={fromDateTime}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-from-date"
                                label="From time:"
                                value={fromDateTime}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-to"
                                label="To date:"
                                disablePast={true}
                                minDate={minToDateTime}
                                format="dd/MM/yyyy"
                                value={toDateTime}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-to-date"
                                label="To time:"
                                value={toDateTime}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className={classes.headerForm}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={4}>
                            <InputLabel>How many questions to display in game? Max {generate ? count : checked.length}</InputLabel>
                            <input
                                onChange={handleDisplayCount}
                                type={'number'}
                                defaultValue={displayCount}
                                min={0}
                                max={generate ? count : checked.length}
                                className={classes.displayNumInput}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="quiz-info"
                                label="Quiz Info"
                                placeholder="Here you can add informatio about quiz"
                                multiline
                                variant="outlined"
                                onChange={handleQuizInfo}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={
                                    <Switch
                                        checked={isOfflineMode}
                                        onChange={handleIsOfflineMode}
                                        name="isOfflineChecked"
                                        color="primary"
                                    />
                                }
                                label="Offline Mode"
                            />
                        </Grid>

                    </Grid>
                </Grid>
            {generate && 
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item xs={6}>
                            <InputLabel>How many questions?</InputLabel>
                            <Input
                                onChange={handleCount}
                                type={'number'}
                                defaultValue={count}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    labelId="category-label"
                                    id="category-select"
                                    value={categoryId}
                                    onChange={handleCategoryId}
                                    autoWidth
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {props.categories.map(cat => { return <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem> })}
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        

                    </Grid>
                
                    
                </Grid>
            }
            
            </Grid>
            {!generate &&
                <div>
                    <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1}}>
                            {questionList.length === 0 && <CircularProgress />}
                            {questionList.length > 0 &&
                                <DataGridPro
                                    columns={columns}
                                    rows={questionList}
                                    checkboxSelection
                                    pagination
                                    autoHeight
                                    rowsPerPageOptions={[5,10,25]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    onSelectionModelChange={id => setChecked(id)}
                                />
                                // <TableContainer component={Paper}>
                                //     <Table className={classes.table} aria-label="simple table">
                                //         <TableHead>
                                //             <TableRow>
                                //                 <TableCell style={{ width: 100 }}>

                                //                 </TableCell>
                                //                 <TableCell style={{ width: 100 }}>ID</TableCell>
                                //                 <TableCell>Question</TableCell>
                                //                 <TableCell style={{ width: 120 }}>Level</TableCell>
                                //             </TableRow>
                                //         </TableHead>
                                //     </Table>
                                //     <div style={{ height: 400, width: '100%', overflow: 'auto' }}>
                                //         <Table>
                                //             <TableBody>
                                //                 {questionList.map((row) => (
                                //                     <TableRow key={row.id} onClick={handleToggle(row.id)}>
                                //                         <TableCell style={{ width: 100 }}>
                                //                             <Checkbox
                                //                                 //onChange={handleSelectedQuestion}
                                //                                 checked={checked.indexOf(row.id) !== -1}
                                //                                 inputProps={{ 'aria-labelledby': row.id }}
                                //                             />
                                //                         </TableCell>
                                //                         <TableCell style={{ width: 100 }}>
                                //                             {row.id}
                                //                         </TableCell>
                                //                         <TableCell>{row.question}</TableCell>
                                //                         <TableCell style={{ width: 100 }}>{row.hardness}</TableCell>
                                //                     </TableRow>
                                //                 ))}
                                //             </TableBody>
                                //         </Table>
                                //     </div>
                                // </TableContainer>
                            }

                            
                        </div>
                    </div>
                </div>
            }
            <div style={{marginTop: '20px'}}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    onClick={handleClose}
                >
                Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleSavePlaylist}
                >
                Save
                </Button>
            </div>
        </div>
    )

}