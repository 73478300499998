import React, { useState } from 'react';
import WQLogin from './components/WQLogin';
import WQDashboard from './components/dashboard/WQDashboard';
import { useHistory } from 'react-router-dom';

function App() {
  let history = useHistory();
  //const [token, setToken] = useState('');

  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem('loggedIn') === 'true' || false
  );

  const login = () => {
    setLoggedIn(true);
    sessionStorage.setItem('loggedIn', 'true');
  };

  const logout = () => {
    setLoggedIn(false);
    sessionStorage.setItem('loggedIn', 'false');
    history.push('/');    
  };

  const getToken = (tkn) => {
    //setToken(tkn);
    sessionStorage.setItem('token', tkn);
  };

  if(!loggedIn) {    
     return <WQLogin login={login} getToken={getToken} />;  
  } else {
    return <WQDashboard logout={logout} />;
  }

}

export default App;
