import { React, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { RadioGroup, Radio } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function WQQuestionNew(props) {

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
        },
        formControl: {
            
            minWidth: 240,
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
            display: 'inline'
        },
    }));

    const [formValidity, setFormValidity] = useState({
        'question': true,
        'category_id': true,
        'hardness': true,
        'answerOne': true,
        'answerTwo': true,
        'answerThree': true,
        'answerFour': true,
    });

    const isEmpty = val => val === '' || val === undefined;
    
    const [questionText, setQuestionText] = useState();
    const handleQuestionText = (event) => {
        setQuestionText(event.target.value);
    };

    const [categoryId, setCategoryId] = useState('');
    const handleCategoryId = (event) => {
        setCategoryId(event.target.value);
    };

    const [hardnessNumber, setHardnessNumber] = useState('');
    const handleHardnessNumber = (event) => {
        setHardnessNumber(event.target.value);
    };
    
    const [answerOne, setAnswerOne] = useState('');
    const handleAnswerOne = (event) => {
        setAnswerOne(event.target.value);
        handleAnswers();
    };

    const [answerTwo, setAnswerTwo] = useState('');
    const handleAnswerTwo = (event) => {
        setAnswerTwo(event.target.value);
        handleAnswers();
    };

    const [answerThree, setAnswerThree] = useState('');
    const handleAnswerThree = (event) => {
        setAnswerThree(event.target.value);
        handleAnswers();
    };

    const [answerFour, setAnswerFour] = useState('');
    const handleAnswerFour = (event) => {
        setAnswerFour(event.target.value);
        handleAnswers();
    };

    const [correctAnswer, setCorrectAnswer] = useState(0);
    const handleCorrectAnswer = (event) => {
        setCorrectAnswer(event.target.value);
        handleAnswers();
    };

    const [answers, setAnswers] = useState();
    const handleAnswers = () => {
        let ans = [
            {
                'answer': answerOne,
                'correct': 0
            },
            {
                'answer': answerTwo,
                'correct': 0
            },
            {
                'answer': answerThree,
                'correct': 0
            },
            {
                'answer': answerFour,
                'correct': 0
            },
        ];

        if (correctAnswer !== undefined) {
            ans[parseInt(correctAnswer)].correct = 1;
        }
        setAnswers(ans);
    };


    const newQuestionHandler = (event) => {
        event.preventDefault();

        const questionIsValid = !isEmpty(questionText);
        const categoryIsValid = !isEmpty(categoryId);
        const hardnessIsValid = !isEmpty(hardnessNumber);
        const answerOneIsValid = !isEmpty(answerOne);
        const answerTwoIsValid = !isEmpty(answerTwo);
        const answerThreeIsValid = !isEmpty(answerThree);
        const answerFourIsValid = !isEmpty(answerFour);
        const correctAnswerIsValid = correctAnswer !== 0;

        setFormValidity({
            'question': questionIsValid,
            'category_id': categoryIsValid,
            'hardness': hardnessIsValid,
            'answerOne': answerOneIsValid,
            'answerTwo': answerTwoIsValid,
            'answerThree': answerThreeIsValid,
            'answerFour': answerFourIsValid,
            'correctAnswer': correctAnswerIsValid
        });

        const validForm = questionIsValid
            && categoryIsValid
            && hardnessIsValid
            && answerOneIsValid
            && answerTwoIsValid
            && answerThreeIsValid
            && answerFourIsValid
            && correctAnswerIsValid;

        if (!validForm) {
            return;
        }

        props.onSaveNew({
            'question': questionText,
            'category_id': categoryId,
            'hardness': hardnessNumber,
            'answers': answers
        });
        
    };

    const classes = useStyles();
    
    const buttonClassname = clsx({
        [classes.buttonSuccess]: props.success,
    });

    return (
        <form>
            <h2 id="transition-modal-title">New Question</h2>
            <p id="transition-modal-description">Use form below to add new question and answers.</p>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="question-text"
                        label="Question"
                        placeholder="Question Text"
                        defaultValue=""
                        fullWidth
                        error={!formValidity.question}
                        margin="normal"
                        onChange={handleQuestionText}
                        InputLabelProps={{
                            shrink: true,
                        }}                        
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <FormControl component="fieldset" error={!formValidity.correctAnswer}>
                        
                        <RadioGroup aria-label="correct" name="correct" onChange={handleCorrectAnswer} style={{ 'width': '100%' }}>
                            <Grid container>

                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-1" fullWidth defaultValue="" error={!formValidity.answerOne} onChange={handleAnswerOne}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value="0" control={<Radio />} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-2" fullWidth defaultValue="" error={!formValidity.answerTwo} onChange={handleAnswerTwo}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value="1" control={<Radio />} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-3" fullWidth defaultValue="" error={!formValidity.answerThree} onChange={handleAnswerThree}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value="2" control={<Radio />} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-4" fullWidth defaultValue="" error={!formValidity.answerFour} onChange={handleAnswerFour}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value="3" control={<Radio />} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}><FormHelperText error={!correctAnswer}>{!correctAnswer ? (<strong style={{ 'fontSize': '18px' }}>Please select correct answer.</strong>) : (<strong style={{ 'fontSize': '18px' }}>Thank you.</strong>)}</FormHelperText></Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>

                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="hardness-label">Level</InputLabel>
                                <Select
                                    labelId="hardness-label"
                                    id="hardness-select"
                                    value={hardnessNumber}
                                    error={!formValidity.hardness}
                                    onChange={handleHardnessNumber}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    labelId="category-label"
                                    id="category-select"
                                    value={categoryId}
                                    error={!formValidity.category_id}
                                    onChange={handleCategoryId}
                                >
                                    {props.categories.map(cat => { return <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem> })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <Grid container>
                        <div style={{ marginTop: '20px' }} className={classes.root}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => { props.onHandleClose() }}
                                type="button"
                            >
                            Close
                            </Button>
                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={buttonClassname}
                                    onClick={newQuestionHandler}
                                >
                                Save
                                </Button>
                                {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>                            
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
