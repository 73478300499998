import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import WQPlaylistNew from '../forms/WQPlaylistNew';
import WQPlaylistEdit from '../forms/WQPlaylistEdit';
import { DataGridPro } from '@mui/x-data-grid-pro';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },
    loader: {
        textAlign: 'right',
        paddingRight: '25px'
    }
}));

export default function WQPlaylists(props) {
    const classes = useStyles();
    
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [openNew, setOpenNew] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [page, setPage] = useState(1);
    const [playlists, setPlaylists] = useState();

    const [openScoreboard, setOpenScoreboard] = useState(false);
    const [openOnlineScore, setOpenOnlineScore] = useState(false);
    const [scoreboard, setScoreboard] = useState({});
    const [onlinescore, setOnlineScore] = useState({});

    const toggleOpenNew = () => {
        setOpenNew(!openNew);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
    };

    const handleOpenScoreboard = (asd) => {
        setOpenScoreboard(asd);
    };
    const handleOpenOnlineScore = (asd) => {
        setOpenOnlineScore(asd);
    };

    const handleScoreboard = (res) => {
        setScoreboard(res);
    };
    const handleOnlineScore = (res) => {
        setOnlineScore(res);
    };

    const [pageSize, setPageSize] = useState(5);

    const columns = [
        { field: 'name', headerName: 'Name', width: 250 },
        {
            field: 'contact',
            headerName: 'Contact',
            width: 250
        },
        {
            field: 'total',
            headerName: 'Score',
            width: 250
        }
    ]

    const onlineColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'name',
            headerName: 'Name',
            width: 250
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250
        },
        {
            field: 'score',
            headerName: 'Score',
            width: 120
        }
    ]; 


    const handleCloseEdit = () => {
        setSelectedPlaylist(null);
        setOpenEdit(false);
    };

    const instance = axios.create({
        baseURL: 'https://kvizapi.europa.rs/api/',
        timeout: 5000,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    const getPlaylists = (asd) => {
        if (sessionStorage.loggedIn) {
            props.showLoaderForPlaylists(true);
            let setsUrl = '/sets';
            instance.get(setsUrl, {

            }).then(response => {
                if (response.status === 200) {
                    props.showLoaderForPlaylists(false);
                    setPlaylists(response.data);
                    setPage(response.data.current_page);
                }
            }).catch(err => {
                console.log(err.response.data.message);
            });
        }
    };

    const savePlaylistNew = (playlistData) => {
        if (sessionStorage.loggedIn) {
            instance.post('/sets',
                playlistData
            ).then(response => {
                if (response.status === 201) {
                    setSuccessMessage('Saved!');
                    setSuccess(true);
                    setOpenNew(false);
                    getPlaylists();
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };

    const savePlaylistEdit = (id, playlistData) => {
        if (sessionStorage.loggedIn) {
            instance.put('/sets/'+id,
                playlistData
            ).then(response => {
                if (response.status === 200) {
                    setSuccessMessage('Saved!');
                    setSuccess(true);
                    setOpenNew(false);
                    getPlaylists();
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };

    const deletePlaylist = (id) => {
        if (sessionStorage.loggedIn) {
            instance.delete('/sets/'+id).then(response => {
                    setSuccessMessage('Deleted!');
                    setSuccess(true);
                    setOpenNew(false);
                    getPlaylists();
            }).catch(error => {
                console.log(error);
            });
        }
    };

    const showScoreboard = (id, offline) => {        
        
        if (sessionStorage.loggedIn) {
            instance.get('/results/' + id).then(response => {
                // setSuccessMessage('Deleted!');
                // setSuccess(true);
                // setOpenNew(false);
                // getPlaylists();
                if (offline) {
                    handleScoreboard(scoreboard => ([...response.data]));
                    handleOpenOnlineScore(false);
                    handleOpenScoreboard(true);
                } else {
                    let maped = response.data.map((el, index) => ({
                        'name': el.users.name,
                        'id': el.user_id,
                        'score': el.total
                    }));

                    instance.get('/users?per_page=999999').then(response => {
                        var filtered = [];

                        for(var arr in response.data.data){
                            for(var filter in maped){
                                if(response.data.data[arr].id === maped[filter].id){
                                    filtered.push({ ...response.data.data[arr], score: maped[filter].score });
                                }
                            }
                        }
                        handleOnlineScore(filtered);
                        handleOpenScoreboard(false);
                        handleOpenOnlineScore(true);
                        
                    })

                
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };
    
    useEffect(() => {
        getPlaylists(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ marginBottom: '17px' }}
            >
                <Grid item xs={2}>
                    <Fab color="primary" aria-label="add" onClick={() => { toggleOpenNew() }}>
                        {!openNew && <AddIcon />}
                        {openNew && <CloseIcon />}
                    </Fab>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={success}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                        message={successMessage}
                    />
                </Grid>
                <Grid item xs={4} container>
                    
                </Grid>
                <Grid item container xs={6} justify="flex-end">
                    
                </Grid>
            </Grid>

            {openNew && 
                <WQPlaylistNew close={handleCloseNew} save={savePlaylistNew} categories={props.categories}/>
            }
            {openEdit && 
                <WQPlaylistEdit id={selectedPlaylist} close={handleCloseEdit} save={savePlaylistEdit} />
            }

            {!openNew && !openEdit && <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Playlist</TableCell>
                            <TableCell>Date From</TableCell>
                            <TableCell>Date To</TableCell>
                            <TableCell>Quiz Access Code</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {playlists !== undefined ? playlists.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                                <TableCell>
                                    {row.date_from}
                                </TableCell>
                                <TableCell>
                                    {row.date_to}
                                </TableCell>
                                <TableCell>
                                    {row.promo_code !== null ? row.promo_code : "-"}
                                </TableCell>
                                <TableCell>
                                    {row.offline === 1 ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() => { showScoreboard(row.id, true) }}
                                    >
                                        Scoreboard 
                                        </Button> : null}
                                    {row.offline === 0 ? 
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() => { showScoreboard(row.id, false) }}
                                    >
                                        Online Scoreboard 
                                        </Button>
                                    : null}
                                    
                                    {/* ovde negde mora modal */}
                                   
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() => { deletePlaylist(row.id) }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer>}


            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ marginTop: '17px', marginBottom: '17px' }}
            >
                <Grid item container xs={12} justify="flex-end">
                    {openScoreboard && 
                        
                     <DataGridPro
                            columns={columns}
                            rows={scoreboard}
                            pagination
                            autoHeight
                            rowsPerPageOptions={[5,10,25]}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        />
                    }
                    { openOnlineScore &&
                        
                     <DataGridPro
                            columns={onlineColumns}
                            rows={onlinescore}
                            pagination
                            autoHeight
                            rowsPerPageOptions={[5,10,25]}
                        pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    )
}
