import { React, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    datewrap: {
        marginBottom: 20
    },
    headerForm: {
        marginBottom: 50
    }
}));

export default function WQPlaylistEdit(props) {
    const classes = useStyles();

    const [questionList, setQuestionList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDateTime, setFromDateTime] = useState(new Date());
    const [toDateTime, setToDateTime] = useState(new Date());
    const [minToDateTime, setMinToDateTime] = useState(new Date());
    const [minFromDateTime, setMinFromDateTime] = useState(new Date());
    const [checked, setChecked] = useState([]);
    const [playlistName, setPlaylistName] = useState();
    

    const handleFromDateChange = (date) => {
        setFromDateTime(date);
        setMinToDateTime(date);
        if (toDateTime < date) {
            setToDateTime(date);
        }
    };
    const handleToDateChange = (date) => {
        if (date > fromDateTime) {
            setToDateTime(date);
        }
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };    

    const handlePlaylistName = (event) => {
        setPlaylistName(event.target.value);
    }

    const handlePlaylistOpen = (pl) => {
        console.log(pl.name);
        setPlaylistName(pl.name);
        setFromDateTime(new Date(pl.date_from));
        setToDateTime(new Date(pl.date_to));
        setMinFromDateTime(new Date(pl.date_from));
        setMinToDateTime(new Date(pl.date_from));
        let ar = [];
        pl.questions.forEach(element => {
            ar.push(parseInt(element.id));
        });
        setChecked(ar);
        setLoading(false);
    }
    

    const instance = axios.create({
        baseURL: 'https://kvizapi.europa.rs/api/',
        timeout: 5000,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    const getQuestions = () => {
        setLoading(true);
        if (sessionStorage.loggedIn) {
            instance.get('/questions?per_page=500', {}).then(response => {
                if (response.status === 200) {
                    setQuestionList(response.data.data);
                    getPlaylist(props.id);
                }
            }).catch(response => {
                console.log(response);
            });
        }
    };

    const getPlaylist = (id) => {
        if (sessionStorage.loggedIn) {
            instance.get('/sets/'+id, {}).then(response => {
                if (response.status === 200) {
                    handlePlaylistOpen(response.data);
                }
            }).catch(response => {
                console.log(response);
            });
        }
    };

    const formatDateTime = (dtm) => {
        let d = dtm.getUTCDate();
        let m = dtm.getUTCMonth() + 1;
        let min = dtm.getUTCMinutes();
        let h = dtm.getUTCHours();
        let year = dtm.getUTCFullYear();
        let month = (m < 10) ? '0'+m.toString() : m;
        let day = (d < 10) ? '0'+ d.toString() : d;
        let hour = (h < 10) ? '0'+h.toString() : h;
        let minutes = (min < 10) ? '0'+min.toString() : min;

        return `${year}-${month}-${day} ${hour}:${minutes}`;
    }

    const handleSavePlaylist = () => {
        let from = formatDateTime(fromDateTime);
        let to = formatDateTime(toDateTime);
        if (checked.length > 0) {

            let playlist = {
                'id': props.id,
                'name': playlistName,
                "date_from": from,
                "date_to": to,
                'questions': checked
            };
            props.save(props.id, playlist);
        }

        console.log(from);

    }

    const handleClose = () => {
        props.close();
    }

    useEffect(() => {
        getQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2>Edit playlist</h2>

            {loading && <CircularProgress /> }
            {!loading && <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6}>
                            <TextField id="playlist-name" label="Playlist name" value={playlistName} onChange={handlePlaylistName} />
                        </Grid>
                        {/* <Grid item container xs={6} justify="flex-end">
                            <Button variant="outlined" onClick={() => { setGenerate(!generate) }}>Generate</Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.headerForm}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-between">
                            <KeyboardDatePicker
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="From date:"
                                disablePast={false}
                                value={fromDateTime}
                                minDate={minFromDateTime}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-from"
                                label="From time:"
                                value={fromDateTime}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="To date:"
                                disablePast={false}
                                minDate={minToDateTime}
                                format="dd/MM/yyyy"
                                value={toDateTime}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-to"
                                label="To time:"
                                value={toDateTime}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            
                {/* {generate &&
                    <Grid item xs={12}>
                
                        <InputLabel>How many questions?</InputLabel>
                        <Input
                            onChange={handleCount}
                            type={'number'}
                            defaultValue={count}
                        />
                    </Grid>
                } */}
            
            </Grid>}
            {!loading &&
                <div>
                    <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                            {questionList.length === 0 && <CircularProgress />}
                            {questionList.length > 0 &&
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: 100 }}>

                                                </TableCell>
                                                <TableCell style={{ width: 100 }}>ID</TableCell>
                                                <TableCell>Question</TableCell>
                                                <TableCell style={{ width: 120 }}>Hardness</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                    <div style={{ height: 400, width: '100%', overflow: 'auto' }}>
                                        <Table>
                                            <TableBody>
                                                {questionList.map((row) => (
                                                    <TableRow key={row.id} onClick={handleToggle(row.id)}>
                                                        <TableCell style={{ width: 100 }}>
                                                            <Checkbox
                                                                //onChange={handleSelectedQuestion}
                                                                checked={checked.indexOf(row.id) !== -1}
                                                                inputProps={{ 'aria-labelledby': row.id }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ width: 100 }}>
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell>{row.question}</TableCell>
                                                        <TableCell style={{ width: 100 }}>{row.hardness}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TableContainer>
                            }

                            
                        </div>
                    </div>
                </div>
            }
            <div style={{marginTop: '20px'}}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    onClick={handleClose}
                >
                Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleSavePlaylist}
                >
                Save
                </Button>
            </div>
        
        </div>
    )

}