import { React, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { RadioGroup, Radio } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1),
//     },
//     formControl: {
        
//         minWidth: 120,
//     },
// }));

export default function WQQuestionEdit(props) {
    
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
        },
        formControl: {
            
            minWidth: 240,
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }));
    
    useEffect(() => {
        //setInitialCorrect();        
        console.log(props.questionText);
    });

    const [formValidity, setFormValidity] = useState({
        'question': true,
        'category_id': true,
        'hardness': true,
        'answerOne': true,
        'answerTwo': true,
        'answerThree': true,
        'answerFour': true,
    });

    const isEmpty = val => val === '' || val === undefined;
    
    const [questionText, setQuestionText] = useState(props.questionText.question);
    const handleQuestionText = (event) => {
        setQuestionText(event.target.value);
    };

    const [categoryId, setCategoryId] = useState(props.questionText.category_id);
    const handleCategoryId = (event) => {
        setCategoryId(event.target.value);
    };

    const [hardnessNumber, setHardnessNumber] = useState(props.questionText.hardness);
    const handleHardnessNumber = (event) => {
        setHardnessNumber(event.target.value);
    };
    
    const [answerOne, setAnswerOne] = useState(props.questionText.answers[0].answer);
    const handleAnswerOne = (event) => {
        setAnswerOne(event.target.value);
        //handleAnswers();
    };

    const [answerTwo, setAnswerTwo] = useState(props.questionText.answers[1].answer);
    const handleAnswerTwo = (event) => {
        setAnswerTwo(event.target.value);
        //handleAnswers();
    };

    const [answerThree, setAnswerThree] = useState(props.questionText.answers[2].answer);
    const handleAnswerThree = (event) => {
        setAnswerThree(event.target.value);
        //handleAnswers();
    };

    const [answerFour, setAnswerFour] = useState(props.questionText.answers[3].answer);
    const handleAnswerFour = (event) => {
        setAnswerFour(event.target.value);
        //handleAnswers();
    };

    const [correctAnswer, setCorrectAnswer] = useState((props.questionText.answers.findIndex(el=>el.correct === 1) !== -1) ? props.questionText.answers.findIndex(el=>el.correct === 1) : undefined);
    const handleCorrectAnswer = (event) => {
        setCorrectAnswer(parseInt(event.target.value));
        handleAnswers(parseInt(event.target.value));
    };

    const [answers, setAnswers] = useState(props.questionText.answers);

    let ans = [];
    const handleAnswers = (c) => {
        ans = [
            {
                'id': props.questionText.answers[0].id,
                'question_id': props.questionText.answers[0].question_id,
                'answer': answerOne,
                'correct': 0
            },
            {
                'id': props.questionText.answers[1].id,
                'question_id': props.questionText.answers[1].question_id,
                'answer': answerTwo,
                'correct': 0
            },
            {
                'id': props.questionText.answers[2].id,
                'question_id': props.questionText.answers[2].question_id,
                'answer': answerThree,
                'correct': 0
            },
            {
                'id': props.questionText.answers[3].id,
                'question_id': props.questionText.answers[3].question_id,
                'answer': answerFour,
                'correct': 0
            },
        ];

        // if (index !== undefined) {
        //     ans[parseInt(index)].correct = 1;
        // }
        // setAnswers(ans);
        // console.log(correctAnswer)
        //let ans = [...props.questionText.answers];

        ans.forEach(el => el.correct = 0);

        c !== undefined ? (ans[parseInt(c)].correct = 1) : (ans[parseInt(correctAnswer)].correct = 1);
        
        setAnswers(ans);
    };


    const saveEdit = (event) => {
        event.preventDefault();

        handleAnswers();

        const questionIsValid = !isEmpty(questionText);
        const categoryIsValid = !isEmpty(categoryId);
        const hardnessIsValid = !isEmpty(hardnessNumber);
        const answerOneIsValid = !isEmpty(answerOne);
        const answerTwoIsValid = !isEmpty(answerTwo);
        const answerThreeIsValid = !isEmpty(answerThree);
        const answerFourIsValid = !isEmpty(answerFour);
        const correctAnswerIsValid = correctAnswer !== undefined;

        setFormValidity({
            'question': questionIsValid,
            'category_id': categoryIsValid,
            'hardness': hardnessIsValid,
            'answerOne': answerOneIsValid,
            'answerTwo': answerTwoIsValid,
            'answerThree': answerThreeIsValid,
            'answerFour': answerFourIsValid,
            'correctAnswer': correctAnswerIsValid
        });

        const validForm = questionIsValid
            && categoryIsValid
            && hardnessIsValid
            && answerOneIsValid
            && answerTwoIsValid
            && answerThreeIsValid
            && answerFourIsValid
            && correctAnswerIsValid;

        if (!validForm) {
            return;
        }
        props.onSaveEdit(props.questionText.id,{
            //'id': props.questionText.id,
            'question': questionText,
            'category_id': categoryId,
            'hardness': hardnessNumber,
            'answers': ans
        });
        
    };

    const classes = useStyles();


    return (
        <form>
            <h2 id="transition-modal-title">Edit Question</h2>
            <p id="transition-modal-description">Use form below to edit question and answers.</p>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="question-text"
                        label="Question"
                        placeholder="Question Text"
                        fullWidth
                        error={!formValidity.question}
                        margin="normal"
                        onChange={handleQuestionText}
                        value={questionText}
                        InputLabelProps={{
                            shrink: true,
                        }}                        
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <FormControl component="fieldset" error={!formValidity.correctAnswer}>
                        
                        <RadioGroup aria-label="correct" name="correct" value={correctAnswer} onChange={handleCorrectAnswer} style={{ 'width': '100%' }}>
                            <Grid container>

                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-1" fullWidth value={answerOne} error={!formValidity.answerOne} onChange={handleAnswerOne}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value={0} control={<Radio checked={answers[0].correct === 1}/>} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-2" fullWidth value={answerTwo} error={!formValidity.answerTwo} onChange={handleAnswerTwo}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value={1} control={<Radio checked={answers[1].correct === 1}/>} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-3" fullWidth value={answerThree} error={!formValidity.answerThree} onChange={handleAnswerThree}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value={2} control={<Radio checked={answers[2].correct === 1}/>} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>                            
                                        <Grid item xs={9}>
                                            <TextField label="Answer-4" fullWidth value={answerFour} error={!formValidity.answerFour} onChange={handleAnswerFour}/>
                                        </Grid>
                                        <Grid item container xs={3} alignItems={ 'center'}>
                                            <FormControlLabel value={3} control={<Radio checked={answers[3].correct === 1}/>} label="Correct"/>    
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}><FormHelperText error={correctAnswer === undefined}>{correctAnswer === undefined ? (<strong style={{ 'fontSize': '18px' }}>Please select correct answer.</strong>) : (<strong style={{ 'fontSize': '18px' }}>Thank you.</strong>)}</FormHelperText></Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>

                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="hardness-label">Level</InputLabel>
                                <Select
                                    labelId="hardness-label"
                                    id="hardness-select"
                                    value={hardnessNumber}
                                    error={!formValidity.hardness}
                                    onChange={handleHardnessNumber}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    labelId="category-label"
                                    id="category-select"
                                    value={categoryId}
                                    error={!formValidity.category_id}
                                    onChange={handleCategoryId}
                                >
                                    {props.categories.map(cat => { return <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem> })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <Grid container>
                        <div style={{ marginTop: '20px' }} className={classes.root}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => { props.onHandleClose() }}
                                type="button"
                            >
                            Close
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={saveEdit}
                            >
                            Save
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
