import { React, useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from './WQListItems';
import WQQuestions from './WQQuestions';
import WQPlaylists from './WQPlaylists';
import WQCategories from './WQCategories';
import WQUsers from './WQUsers';
import LinearProgress from "@material-ui/core/LinearProgress";
import { Route, useHistory, useLocation } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://euinfo.rs/">
        EU Info Centar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative'
  },
  loaderContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fixedLoader: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%'
  }
}));

export default function Dashboard(props) {

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState('');

  const showLoader = (asd) => {
    setIsLoading(asd);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };  

  const instance = axios.create({
      baseURL: 'https://kvizapi.europa.rs/api/',
      timeout: 1000,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
  });

  const getCategories = () => {        
      if (sessionStorage.loggedIn) {
          showLoader(true);
          const categoriesUrl = '/categories';            
          instance.get(categoriesUrl, {
              
          }).then(response => {
              if(response.status === 200) {                  
                setCategories(response.data);
                console.log(response.data);
              }        
          }).catch(err => {
            console.log(err.response.data.message);
          }).finally(showLoader(false));
      }
  };

  // fix history
  let history = useHistory();

  const handleInitRedirect = () => {    
    history.push('/questions');
  }
  
  let location = useLocation();

  useEffect(() => {
    
    getCategories();
    if (location.pathname === '/') {
      
      handleInitRedirect();
    }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Dashboard
          </Typography>
          <Fab variant="extended" onClick={props.logout}>
            <ExitToAppIcon /> Logout
          </Fab>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>        
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.loaderContainer}>
          {!categories.length > 0 && <div className={classes.fixedLoader}><LinearProgress /></div>}          
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          {categories.length > 0 && <Grid container spacing={3}>
            {/* Question list */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                
                  <Route path="/dashboard">
                    <h2>Welcome, Admin</h2>
                    <p>to Quiz administration pages.</p>
                    <p>Current quiz: </p>
                  </Route>
                  <Route path="/questions">
                    <WQQuestions showLoaderForQuestions={showLoader} isLoading={isLoading} categories={categories} />
                  </Route>
                  <Route path="/playlists">
                    <WQPlaylists showLoaderForPlaylists={showLoader} isLoading={isLoading}  categories={categories}/>
                  </Route>
                  <Route path="/categories">
                    <WQCategories showLoaderForCategories={showLoader} isLoading={isLoading} categories={categories} getCategories={getCategories}/>
                  </Route>
                  <Route path="/users">
                    <WQUsers showLoaderForUsers={showLoader} isLoading={isLoading} />
                  </Route>
                
              </Paper>
            </Grid>
          </Grid>}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}