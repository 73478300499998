import { React, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    button: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },
    loader: {
        textAlign: 'right',
        paddingRight: '25px'
    }
}));

export default function WQCategories(props) {
    const classes = useStyles();
    const [categoryName, setCategoryName] = useState('');
    const [openNew, setOpenNew] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const toggleOpenNew = () => {
        setOpenNew(!openNew);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
    };

    const handleSaveCategory = () => {
        let cat = {
            'name': categoryName
        };
        saveCategory(cat);
    };

    const handleName = (event) => {
        if (event.target.value.length > 3) {
            setCategoryName(event.target.value);
        }
    };

    const instance = axios.create({
        baseURL: 'https://kvizapi.europa.rs/api/',
        timeout: 5000,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    const saveCategory = (cat) => {
        if (sessionStorage.loggedIn) {
            props.showLoaderForCategories(true);
            let setsUrl = '/categories';
            instance.post(setsUrl, cat).then(response => {
                if (response.status === 201) {
                    props.getCategories();
                    setSuccessMessage('Saved!');
                    setSuccess(true);
                    setOpenNew(false);
                }
            }).catch(err => {
                console.log(err.response.data.message);
            }).finally(props.showLoaderForCategories(false));
        }
    };

    const handleDeleteCategory = (id) => {
        if (sessionStorage.loggedIn) {
            props.showLoaderForCategories(true);
            let setsUrl = '/categories/' + id;
            instance.delete(setsUrl).then(response => {
                if (response.data.status === 'Success') {
                    props.getCategories();
                    setSuccessMessage('Deleted!');
                    setSuccess(true);
                }
                console.log(response)
            }).catch(err => {
                console.log(err.response.data.message);
            }).finally(props.showLoaderForCategories(false));
        }
    }
    

    return (
        <div>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ marginBottom: '17px' }}
            >
                <Grid item xs={2}>
                    <Fab color="primary" aria-label="add" onClick={() => { toggleOpenNew() }}>
                        {!openNew && <AddIcon />}
                        {openNew && <CloseIcon />}
                    </Fab>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={success}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                        message={successMessage}
                    />
                </Grid>
                
            </Grid>
            {openNew &&
                <div>                
                    <Grid container>
                        <Grid item xs={12}>
                            <h2>Add new category</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="flex-end">
                                <Grid item xs={6}>
                                    <TextField id="category-name" label="Category name" autoFocus={true} onChange={handleName}/>
                                    <div style={{marginTop: '20px'}}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={classes.button}
                                            onClick={handleCloseNew}
                                        >
                                        Close
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className={classes.button}
                                            onClick={handleSaveCategory}
                                        >
                                        Save
                                        </Button>
                                    </div>
                                </Grid>                                
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
            {!openNew && <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Questions count</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.categories !== undefined && props.categories.map(cat => {
                            return <TableRow key={cat.id}>
                                <TableCell component="th" scope="row">
                                    {cat.id}
                                </TableCell>
                                <TableCell>
                                    {cat.name}
                                </TableCell>
                                <TableCell>
                                    {cat.questions_count}
                                </TableCell>
                                <TableCell>
                                    {cat.questions_count === 0 &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}
                                        onClick={()=>{ handleDeleteCategory(cat.id) }}
                                    >
                                        Delete
                                    </Button>}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    )
}
